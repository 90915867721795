import React, { Component } from "react"
import styled from "styled-components"
import {
    HiAnnotation,
    HiArchive, HiArrowRight, HiChat,
    HiCheck,
    HiClock,
    HiDocument, HiDocumentDownload,
    HiFingerPrint, HiLockClosed,
    HiPencil, HiPlus, HiReply,
    HiSwitchHorizontal, HiThumbDown,
    HiThumbUp,
    HiTrash,
    HiTrendingUp, HiX,
} from 'react-icons/hi';
import moment from "moment"
import Navigation from "../../components/Navigation"
import CreateWatchModal from "./CreateWatchModal";
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Button,
    Box,
    Divider,
    AbsoluteCenter,
    PopoverFooter,
    ButtonGroup,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    Tag,
    Tooltip,
    ModalOverlay,
    Modal,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalContent,
    Thead,
    Tr,
    Th,
    Td,
    TableContainer,
    Table,
    Tbody,
    Tfoot,
    Checkbox,
    TableCaption,
    RadioGroup,
    Stack,
    Radio,
    FormLabel,
    Input,
    FormControl,
} from '@chakra-ui/react';
import VerifyWatchModal from './VerifyWatchModal';
import RepairWatchModal from './RepairWatchModal';
import { HiArchiveBox, HiBanknotes, HiWrench } from 'react-icons/hi2';
import { Step, Steps } from 'chakra-ui-steps';

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
});

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 32px;
    width: 100%;
  
    @media screen and (max-width: 1279px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const StyledWatch = styled.div`
    width: 100%;
    height: 288px;
    box-sizing: border-box;
    padding: 16px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: opacity 0.3s;
    position: relative;
    
    .certified {
        position: absolute;
        right: 16px;
        bottom: -12px;
        height: 24px;
        display: flex;
        align-items: center;
        background: #1abc9c;
        color: white;
        padding: 0 12px;
        border-radius: 4px;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
        font-size: 12px;
    }
  
    &:hover {
        opacity: 0.75;
    }
  
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        -webkit-user-drag: none;
    }
`

class Watch extends Component {
    render() {
        const service = this.props.services.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())[0];

        return <StyledWatch onClick={this.props.onClick}>
            <img src={this.props.src} />
            {service && service.status === "waitingForKVA" && <div className="certified" style={{ backgroundColor: "#3498db" }}><HiClock style={{ fontSize: 16, marginRight: 4 }} />Kostenvoranschlag wird erstellt</div>}
            {service && service.status === "kvaReceived" && <div className="certified" style={{ backgroundColor: "#2ecc71" }}><HiBanknotes style={{ fontSize: 16, marginRight: 4 }} />Kostenvoranschlag erhalten</div>}
            {/*service && service.status === "offerDeclined" && <div className="certified" style={{ backgroundColor: "#e74c3c" }}><HiX style={{ fontSize: 16, marginRight: 4 }} />Im Rückversand</div>*/}
            {service && service.status === "offerReceived" && <div className="certified" style={{ backgroundColor: "#2ecc71" }}><HiBanknotes style={{ fontSize: 16, marginRight: 4 }} />Finales Angebot erhalten</div>}
            {service && service.status === "offerApproved" && <div className="certified" style={{ backgroundColor: "#2ecc71" }}><HiCheck style={{ fontSize: 16, marginRight: 4 }} />Angebot angenommen</div>}
            {service && service.status === "customerApprovedKVA" && <div className="certified" style={{ backgroundColor: "#3498db" }}><HiClock style={{ fontSize: 16, marginRight: 4 }} />Versand in Klärung</div>}
            {service && service.status === "shippingClarification" && <div className="certified" style={{ backgroundColor: "#3498db" }}><HiClock style={{ fontSize: 16, marginRight: 4 }} />Versand in Klärung</div>}
            {service && service.status === "watchReceived" && <div className="certified" style={{ backgroundColor: "#3498db" }}><HiClock style={{ fontSize: 16, marginRight: 4 }} />Auftrag in Prüfung</div>}
            {service && service.status === "inProgress" && <div className="certified" style={{ backgroundColor: "#e67e22" }}><HiWrench style={{ fontSize: 16, marginRight: 4 }} />Service in Arbeit</div>}
            {service && service.status === "approval" && <div className="certified" style={{ backgroundColor: "#3498db" }}><HiChat style={{ fontSize: 16, marginRight: 4 }} />Wir brauchen dein Feedback</div>}
            {service && service.status === "cancelledAndShipping" && <div className="certified" style={{ backgroundColor: "#e74c3c" }}><HiArchiveBox style={{ fontSize: 16, marginRight: 4 }} />Im Rückversand</div>}
            {service && service.status === "waitingForPayment" && <div className="certified" style={{ backgroundColor: "#e67e22" }}><HiBanknotes style={{ fontSize: 16, marginRight: 4 }} />Offene Zahlung</div>}
            {service && service.status === "companyDeclined" && <div className="certified" style={{ backgroundColor: "#e74c3c" }}><HiX style={{ fontSize: 16, marginRight: 4 }} />Service nicht möglich</div>}
            {/*{!this.props.waitingForVerification && this.props.certified && <div className="certified"><HiCheck style={{ fontSize: 16, marginRight: 4 }} />Authenzität bestätigt</div>}
            {!this.props.waitingForVerification && !this.props.certified && <div className="certified" style={{ backgroundColor: "#e67e22" }}><HiX style={{ fontSize: 16, marginRight: 4 }} />Authenzität unbestätigt</div>}
            {this.props.waitingForVerification && <div className="certified" style={{ backgroundColor: "#3498db" }}><HiClock style={{ fontSize: 16, marginRight: 4 }} />Warte auf Verifizierung</div>}*/}
        </StyledWatch>
    }
}

const AddWatch = styled.div`
    width: 100%;
    height: 288px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 48px;
    text-align: center;
    user-select: none;
    cursor: pointer;
    transition: border-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:hover {
        border-color: rgba(0, 0, 0, 0.5);
    }
`

const Titles = styled.div`
    h1 {
        font-size: 24px;
        margin-top: 16px;
    }

    h2 {
        font-size: 18px;
        margin-bottom: 32px;
    }
`

export { Titles }

export default class extends Component {
    state = { drawerOpen: false, watches: null, watch: null, selectedPositions: [] }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        fetch(window.watchstorage.backendUrl + "/api/v1/watches", {
            method: "GET",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${global.WatchStorage.session.token}`
            }
        }).then(async result => {
            if(result.status === 200) {
                const watches = await result.json()

                this.setState({ watches })
            } else {
                console.log(result)
            }
        }).catch(console.error)
    }

    render() {
        const currentService = this.state.watch ? this.state.watch.services.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())[0] : null;
        const currentServiceDone = currentService ? ([ "finished", "customerDeclinedKVA", "offerDeclined" ].includes(currentService.status)) : true;

        console.log(currentService)
        console.log(this.state.selectedPositions)

        return <>
            <Modal size="xl" onClose={() => this.setState({ kvaModalOpen: false })} isOpen={this.state.kvaModalOpen} isCentered>
                <ModalOverlay />
                <ModalContent maxW="1100px">
                    <ModalHeader>{currentService?.status === "offerReceived" ? "Angebot überprüfen" : "Kostenvoranschlag überprüfen"}</ModalHeader>
                    <ModalCloseButton />
                    {currentService && <ModalBody>
                        <Steps activeStep={this.state.step}>
                            {[
                                {
                                    label: 'Prüfen',
                                    content: <TableContainer>
                                        <Table variant="simple" size="lg">
                                            {currentService.notes && <TableCaption><b>Kommentar von Horoluma: </b><br />{currentService.notes}</TableCaption>}
                                            <Thead>
                                                <Tr>
                                                    <Th>Beauftragen</Th>
                                                    <Th>Position</Th>
                                                    <Th isNumeric>Menge</Th>
                                                    <Th isNumeric>Einzelpreis</Th>
                                                    <Th isNumeric>Gesamt</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {currentService.positions.map(position => {
                                                    return <Tr style={{ opacity: this.state.selectedPositions.includes(position.id) ? 1 : 0.5 }} key={position.id}>
                                                        <Td><Checkbox isDisabled={!position.optional || position.declined || (currentService?.status === "offerReceived")} isChecked={this.state.selectedPositions.includes(position.id)} onChange={e => {
                                                            if(e.target.checked) {
                                                                this.setState({ selectedPositions: [ ...this.state.selectedPositions, position.id ] })
                                                            } else {
                                                                this.setState({ selectedPositions: this.state.selectedPositions.filter(sp => sp !== position.id) })
                                                            }
                                                        }} /></Td>
                                                        <Td>{position.text}</Td>
                                                        <Td isNumeric>{position.amount}</Td>
                                                        <Td isNumeric>{formatter.format(parseFloat(position.price))}</Td>
                                                        <Td isNumeric>{formatter.format(parseFloat(position.price) * parseFloat(position.amount))}</Td>
                                                    </Tr>
                                                })}
                                            </Tbody>
                                            <Tfoot>
                                                <Tr>
                                                    <Th>Netto</Th>
                                                    <Th></Th>
                                                    <Th></Th>
                                                    <Th></Th>
                                                    <Th isNumeric>{formatter.format(currentService.positions.filter(p => this.state.selectedPositions.includes(p.id)).map(p => parseFloat(p.price) * parseFloat(p.amount)).reduce((a, b) => a + b, 0))}</Th>
                                                </Tr>
                                                <Tr>
                                                    <Th>Brutto ({currentService.taxRate}% MwSt)</Th>
                                                    <Th></Th>
                                                    <Th></Th>
                                                    <Th></Th>
                                                    <Th isNumeric>{formatter.format(currentService.positions.filter(p => this.state.selectedPositions.includes(p.id)).map(p => parseFloat(p.price) * parseFloat(p.amount)).reduce((a, b) => a + b, 0) * ((currentService.taxRate + 100) / 100))}</Th>
                                                </Tr>
                                            </Tfoot>
                                        </Table>
                                    </TableContainer>
                                },
                                ...(currentService?.status === "offerReceived" ? [] : [{
                                    label: 'Versand',
                                    content: <div>
                                        <h2 style={{ marginTop: 16, fontSize: 20, fontWeight: 'bold' }}>Versandpräferenz</h2>
                                        <h3 style={{ marginTop: 8, marginBottom: 16 }}>Bitte wähle deine gewünschte Versandpräferenz aus. Wir treten nach Abschicken des Auftrags mit dir in Kontakt, um die Details zu besprechen.</h3>
                                        <RadioGroup onChange={deliveryPreference => this.setState({ deliveryPreference })} value={this.state.deliveryPreference}>
                                            <Stack>
                                                <Radio value='versand'>Ich versende selber</Radio>
                                                {this.state.deliveryPreference === "versand" && <p style={{ marginBottom: 16 }}>Du erhältst nach Abschluss der Annahme des Kostenvoranschlags per E-Mail alle Informationen, wie du dein Paket zu uns senden kannst.</p>}
                                                <Radio value='abholung'>Ich möchte abholen lassen</Radio>
                                                {this.state.deliveryPreference === "abholung" && <div>
                                                    <p style={{marginBottom: 16}}>Nach Abschluss der Annahme des Kostenvoranschlags kontaktieren wir dich unter der angegebenen Telefonnummer um die Details der Abholung zu klären.</p>
                                                    <div style={{
                                                        display: "grid",
                                                        gridGap: 16,
                                                        marginBottom: 16,
                                                        gridAutoFlow: "column"
                                                    }}>
                                                        <FormControl>
                                                            <FormLabel>Vor- und Nachname*</FormLabel>
                                                            <Input
                                                                size="lg"
                                                                required
                                                                onChange={value => this.setState({name: value.target.value})}
                                                                value={this.state.name}
                                                            />
                                                        </FormControl>
                                                        <FormControl>
                                                            <FormLabel>Telefonnummer*</FormLabel>
                                                            <Input
                                                                size="lg"
                                                                required
                                                                onChange={value => this.setState({telephone: value.target.value})}
                                                                value={this.state.telephone}
                                                            />
                                                        </FormControl>
                                                        <FormControl>
                                                            <FormLabel>Geschätzter Paketwert in EUR*</FormLabel>
                                                            <Input
                                                                size="lg"
                                                                required
                                                                onChange={value => this.setState({worth: value.target.value})}
                                                                value={this.state.worth}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <FormControl>
                                                        <FormLabel>Straße und Hausnr.*</FormLabel>
                                                        <Input
                                                            size="lg"
                                                            required
                                                            onChange={value => this.setState({street: value.target.value})}
                                                            value={this.state.street}
                                                        />
                                                    </FormControl>
                                                    <div style={{
                                                        display: "grid",
                                                        gridGap: 16,
                                                        marginTop: 16,
                                                        gridAutoFlow: "column",
                                                        marginBottom: 16
                                                    }}>
                                                        <FormControl>
                                                            <FormLabel>Postleitzahl*</FormLabel>
                                                            <Input
                                                                size="lg"
                                                                required
                                                                onChange={value => this.setState({postalCode: value.target.value})}
                                                                value={this.state.postalCode}
                                                            />
                                                        </FormControl>
                                                        <FormControl>
                                                            <FormLabel>Stadt*</FormLabel>
                                                            <Input
                                                                size="lg"
                                                                required
                                                                onChange={value => this.setState({city: value.target.value})}
                                                                value={this.state.city}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>}
                                                <Radio value='persoenlich'>Ich möchte persönlich
                                                    abgeben</Radio>
                                                {this.state.deliveryPreference === "persoenlich" && <div>
                                                    <p style={{ marginBottom: 16 }}>Du erhältst nach Abschluss der
                                                        Annahme des Kostenvoranschlags per E-Mail alle Informationen,
                                                        wie, wann und wohin du dein Paket zu uns bringen kannst. Bitte hinterlasse deine Telefonnummer, damit wir einen gemeinsamen Termin abstimmen können.</p>
                                                    <FormControl>
                                                        <FormLabel>Telefonnummer*</FormLabel>
                                                        <Input
                                                          size="lg"
                                                          required
                                                          onChange={value => this.setState({ telephone: value.target.value })}
                                                          value={this.state.telephone}
                                                        />
                                                    </FormControl>
                                                </div>}
                                            </Stack>
                                        </RadioGroup>
                                    </div>
                                }]),
                                {
                                    label: 'Abschluss',
                                    content: <div>
                                        <h2 style={{ marginTop: 16, fontSize: 20, fontWeight: 'bold' }}>Disclaimer</h2>
                                        {currentService?.status === 'offerReceived' ? <h3 style={{marginTop: 8, marginBottom: 16}}>Hiermit beauftrage ich Horoluma,
                                            den Service zu den im Angebot aufgeführten Preisen durchzuführen.
                                            Ich verstehe, dass es sich hierbei um eine verbindliche
                                            Bestellung handelt. Der Service wird schnellstmöglich nach Beauftragung begonnen.</h3> : <h3 style={{marginTop: 8, marginBottom: 16}}>Hiermit
                                        beauftrage ich Horoluma,
                                        den Service zu den im Kostenvoranschlag aufgeführten Preisen durchzuführen.
                                        Ich verstehe, dass es sich hierbei noch nicht um eine verbindliche
                                        Bestellung handelt. Im Falle der Nichtdurchführbarkeit oder Stornierung
                                        trage ich die anfallenden Kosten für (Rück-)Versand. Nach Erhalt der Uhr und
                                        vor Durchführung des Services erhalte ich von Horoluma ein Endangebot,
                                        welches bei Annahme zu einer verbindlichen Bestellung führt. Nach Annahme des
                                        Kostenvoranschlags tritt Horoluma mit mir in Kontakt, um die genauen Details
                                        bzgl. des Versands zu besprechen.</h3>
                                }
                                <Checkbox isChecked={this.state.agb} onChange={e => this.setState({ agb: e.target.checked })}>Ich akzeptiere den Disclaimer & die <a target="_blank" style={{ color: "#3498db" }} href="https://horoluma.com/pricing/">AGB</a></Checkbox>
                                    </div>
                                }
                            ].map(({ label, content }, index) => (
                              <Step label={label} key={label} onClick={() => {
                                  if(index < this.state.step) this.setState({ step: index })
                              }}>
                                  {content}
                              </Step>
                            ))}
                        </Steps>
                    </ModalBody>}
                    <ModalFooter>
                        <ButtonGroup>
                            {this.state.step === 0 && currentService?.status !== "offerReceived" && <Popover placement="top" isOpen={this.state.popoverOpenDecline}>
                                <PopoverTrigger>
                                    <Button variant="outline" leftIcon={<HiThumbDown />} colorScheme="red" onClick={() => this.setState({ popoverOpenDecline: true })}>Ablehnen</Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverHeader>Kostenvoranschlag ablehnen</PopoverHeader>
                                    <PopoverBody>Bist du sicher, dass du den Kostenvoranschlag ablehnen möchtest? Deine Serviceanfrage wird dadurch abgebrochen.<br /><br />Diese Aktion ist nicht widerrufbar.</PopoverBody>
                                    <PopoverFooter display='flex' justifyContent='flex-end'>
                                        <ButtonGroup size='sm'>
                                            <Button onClick={() => {
                                                this.setState({ popoverOpenDecline: false })
                                            }}>Abbrechen</Button>
                                            <Button onClick={() => {
                                                this.setState({ popoverOpenDecline: false, kvaModalOpen: false })

                                                fetch(window.watchstorage.backendUrl + "/api/v1/watchServices/customer-decline", {
                                                    method: "POST",
                                                    credentials: "include",
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                                    },
                                                    body: JSON.stringify({
                                                        id: currentService.id
                                                    })
                                                }).then(async res => {
                                                    if(res.status === 201) {
                                                        this.fetch()

                                                        window.toast({
                                                            title: 'Kostenvoranschlag abgelehnt',
                                                            description: 'Wir haben deine Ablehnung erhalten.',
                                                            status: 'success',
                                                            duration: 9000,
                                                            isClosable: true,
                                                        })
                                                    }
                                                })
                                            }} colorScheme='red'>Bestätigen</Button>
                                        </ButtonGroup>
                                    </PopoverFooter>
                                </PopoverContent>
                            </Popover>}
                            {this.state.step === 0 && currentService?.status === "offerReceived" && <Popover placement="top" isOpen={this.state.popoverOpenDecline}>
                                <PopoverTrigger>
                                    <Button variant="outline" leftIcon={<HiThumbDown />} colorScheme="red" onClick={() => this.setState({ popoverOpenDecline: true })}>Ablehnen</Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverHeader>Angebot ablehnen</PopoverHeader>
                                    <PopoverBody>Bist du sicher, dass du das Angebot ablehnen möchtest? Deine Serviceanfrage wird dadurch abgebrochen. Wir setzen uns nach Ablehnung unmittelbar mit dir in Verbindung, um den Rückversand deiner Sendung zu klären.<br /><br />Diese Aktion ist nicht widerrufbar.</PopoverBody>
                                    <PopoverFooter display='flex' justifyContent='flex-end'>
                                        <ButtonGroup size='sm'>
                                            <Button onClick={() => {
                                                this.setState({ popoverOpenDecline: false })
                                            }}>Abbrechen</Button>
                                            <Button onClick={() => {
                                                this.setState({ popoverOpenDecline: false, kvaModalOpen: false })

                                                fetch(window.watchstorage.backendUrl + "/api/v1/watchServices/customer-decline-offer", {
                                                    method: "POST",
                                                    credentials: "include",
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                                    },
                                                    body: JSON.stringify({
                                                        id: currentService.id
                                                    })
                                                }).then(async res => {
                                                    if(res.status === 201) {
                                                        this.fetch()

                                                        window.toast({
                                                            title: 'Angebot abgelehnt',
                                                            description: 'Wir haben deine Ablehnung erhalten. Du erhältst eine Bestätigung per E-Mail.',
                                                            status: 'success',
                                                            duration: 9000,
                                                            isClosable: true,
                                                        })
                                                    }
                                                })
                                            }} colorScheme='red'>Bestätigen</Button>
                                        </ButtonGroup>
                                    </PopoverFooter>
                                </PopoverContent>
                            </Popover>}
                            <Button leftIcon={currentService?.status === "offerReceived" ? (this.state.step === 1 ? <HiCheck />: <HiArrowRight />) : (this.state.step === 2 ? <HiCheck />: <HiArrowRight />)} isDisabled={this.state.selectedPositions.length === 0 || (!this.state.agb && (currentService?.status === "offerReceived" ? (this.state.step === 1) : (this.state.step === 2))) || (this.state.step === 1 && this.state.deliveryPreference === "persoenlich" && !this.state.telephone) || (this.state.step === 1 && this.state.deliveryPreference === "abholung" && (!this.state.street || !this.state.name || !this.state.telephone || !this.state.postalCode || !this.state.worth || !this.state.city))} colorScheme="green" onClick={() => {
                                if(currentService?.status === "offerReceived") {
                                    if(this.state.step < 1) {
                                        this.setState({ step: this.state.step + 1 })
                                    } else {
                                        this.setState({ kvaModalOpen: false })

                                        fetch(window.watchstorage.backendUrl + "/api/v1/watchServices/accept-offer", {
                                            method: "POST",
                                            credentials: "include",
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                            },
                                            body: JSON.stringify({
                                                id: currentService.id
                                            })
                                        }).then(async res => {
                                            if(res.status === 201) {
                                                this.fetch()

                                                window.toast({
                                                    title: 'Angebot angenommen',
                                                    description: 'Wir freuen uns über deinen Auftrag! Du hast erhältst in wenigen Minuten eine Bestätigung per E-Mail mit allen Infos, wie es weitergeht.',
                                                    status: 'success',
                                                    duration: 9000,
                                                    isClosable: true,
                                                })
                                            } else {
                                                window.toast({
                                                    title: 'Ein Fehler ist aufgetreten.',
                                                    description: res.statusText,
                                                    status: 'error',
                                                    duration: 9000,
                                                    isClosable: true,
                                                })
                                            }
                                        })
                                    }
                                } else {
                                    if(this.state.step < 2) {
                                        this.setState({ step: this.state.step + 1 })
                                    } else {
                                        this.setState({ kvaModalOpen: false })

                                        fetch(window.watchstorage.backendUrl + "/api/v1/watchServices/accept", {
                                            method: "POST",
                                            credentials: "include",
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                            },
                                            body: JSON.stringify({
                                                id: currentService.id,
                                                deliveryPreference: this.state.deliveryPreference,
                                                selectedPositions: this.state.selectedPositions,
                                                telephone: this.state.telephone || undefined,
                                                name: this.state.name || undefined,
                                                street: this.state.street || undefined,
                                                postalCode: this.state.postalCode || undefined,
                                                city: this.state.city || undefined,
                                                worth: this.state.worth || undefined
                                            })
                                        }).then(async res => {
                                            if(res.status === 201) {
                                                this.fetch()

                                                window.toast({
                                                    title: 'Kostenvoranschlag angenommen',
                                                    description: 'Wir freuen uns über deinen Auftrag! Du hast erhältst in wenigen Minuten eine Bestätigung per E-Mail mit allen Infos, wie es weitergeht.',
                                                    status: 'success',
                                                    duration: 9000,
                                                    isClosable: true,
                                                })
                                            } else {
                                                window.toast({
                                                    title: 'Ein Fehler ist aufgetreten.',
                                                    description: res.statusText,
                                                    status: 'error',
                                                    duration: 9000,
                                                    isClosable: true,
                                                })
                                            }
                                        })
                                    }
                                }
                            }}>{(currentService?.status === "offerReceived" ? (this.state.step === 1 ? "Beauftragen" : "Weiter") : (this.state.step === 2 ? "Abschicken" : "Weiter"))}</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size="xl" onClose={() => this.setState({ approvalModalOpen: false })} isOpen={this.state.approvalModalOpen} isCentered>
                <ModalOverlay />
                <ModalContent maxW="1100px">
                    <ModalHeader>Serviceergebnis freigeben</ModalHeader>
                    <ModalCloseButton />
                    {currentService && <ModalBody>
                        <p style={{ marginBottom: 16 }}>Solltest du mit dem Ergebnis nicht zufrieden sein, bitten wir dich darum, unseren Kundenservice unter den bekannten Daten zu kontaktieren.</p>
                        <div style={{ display: "grid", gridAutoFlow: "column", gridGap: 16, maxHeight: 600, overflowY: "scroll" }}>
                            {currentService.images.filter(img => img.type === "final").map((image, key) => <img src={image.data} key={key} style={{ height: 250, border: "1px solid rgba(0, 0, 0, 0.1)", padding: 16 }} />)}
                        </div>
                    </ModalBody>}
                    <ModalFooter>
                        <ButtonGroup>
                            <Button leftIcon={<HiCheck />} colorScheme="green" onClick={() => {
                                fetch(window.watchstorage.backendUrl + "/api/v1/watchServices/approve", {
                                    method: "POST",
                                    credentials: "include",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                    },
                                    body: JSON.stringify({
                                        id: currentService.id
                                    })
                                }).then(async res => {
                                    if(res.status === 201) {
                                        this.fetch()
                                        this.setState({ approvalModalOpen: false })

                                        window.toast({
                                            title: 'Serviceergebnis freigegeben',
                                            description: 'Vielen Dank für deine Freigabe. Du erhältst kurzfristig eine E-Mail mit den nächsten Schritten.',
                                            status: 'success',
                                            duration: 9000,
                                            isClosable: true,
                                        })
                                    }
                                })
                            }}>Service freigeben</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Drawer placement="right" onClose={() => {
                this.setState({ drawerOpen: false, popoverOpenWithdraw: false })
            }} isOpen={this.state.drawerOpen}>
                <DrawerOverlay />
                {this.state.drawerOpen && <DrawerContent>
                    <DrawerHeader borderBottomWidth='1px'>Uhr verwalten</DrawerHeader>
                    <DrawerBody>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: 16, marginTop: 12 }}>
                            {/*<Stat>
                                <StatLabel>Tagesaktueller Wert</StatLabel>
                                <StatNumber>4.129 EUR</StatNumber>
                                <StatHelpText>
                                    <StatArrow type='increase' />
                                    23.36%
                                </StatHelpText>
                            </Stat>*/}
                            {/*<Box position='relative' marginBottom="8px">
                                <Divider />
                                <AbsoluteCenter bg='white' px='4'>
                                    Status
                                </AbsoluteCenter>
                            </Box>*/}
                            {/*{this.state.watch.verifiedDate && <Tag size="lg" colorScheme='green' mb="8px" pt="4px" pb="4px">
                                <HiCheck style={{ marginRight: 8 }} /> Verifiziert am {moment(this.state.verifiedDate).format("DD.MM.YYYY")}
                            </Tag>}
                            {!this.state.watch.verifiedDate && <Tag size="lg" colorScheme='orange' mb="8px" pt="4px" pb="4px">
                                <HiCheck style={{ marginRight: 8 }} /> Authenzität unbestätigt
                            </Tag>}*/}
                            {/*currentServiceDone && <Box position='relative' marginBottom="8px">
                                <Divider />
                                <AbsoluteCenter bg='white' px='4'>
                                    Daten ändern
                                </AbsoluteCenter>
                            </Box>*/}
                            {!currentServiceDone && <Box position='relative' marginBottom="8px">
                                <Divider />
                                <AbsoluteCenter bg='white' px='4'>
                                    Serviceanfrage
                                </AbsoluteCenter>
                            </Box>}
                            {!currentServiceDone && <p>Service <b>{currentService.number}</b> vom {moment(currentService.createdAt).format("DD.MM.YYYY")}</p>}
                            {!currentServiceDone && currentService.status === "offerApproved" && <p>Wir beginnen nun schnellstmöglich mit dem Service und informieren dich umgehend bei Fortschritten.</p>}
                            {!currentServiceDone && currentService.status === "watchReceived" && <p>Wir haben deine Sendung erhalten und erstellen nun ein passendes Angebot. Wir melden uns schnellstmöglich bei dir.</p>}
                            {!currentServiceDone && currentService.status === "inProgress" && <p>Dein Service ist nun in Arbeit. Wir melden uns umgehend nach Abschluss des Auftrags.</p>}
                            {!currentServiceDone && currentService.status === "waitingForPayment" && <p>Wir warten nun auf den Zahlungseingang der Rechnung, welche wir dir kurzfristig per E-Mail zukommen lassen. Nach vollständigem Erhalt der Zahlung leiten wir unmittelbar den Rückversand ein. Solltest du bereits bezahlt haben, wurde die Zahlung bei uns möglicherweise noch nicht verbucht.</p>}
                            {!currentServiceDone && currentService.status === "cancelledAndShipping" && <p>Wir verarbeiten aktuell den Rückversand deiner Sendung. Sobald der Vorgang abgeschlossen ist, kannst du für deine Uhr eine neue Serviceanfrage stellen.</p>}
                            {!currentServiceDone && currentService.status === "approval" && <p>Wir brauchen dein Feedback zum geleisteten Service, damit wir den Rückversand deiner Uhr einleiten können.</p>}
                            {!currentServiceDone && currentService.status === "companyDeclined" && <p>Leider können wir dir aktuell keinen Kostenvoranschlag für die angefragten Leistungen unterbreiten. Schließe die Serviceanfrage, um die Uhr für die Bearbeitung freizuschalten.</p>}
                            {!currentServiceDone && currentService.status === "kvaReceived" && <p>Prüfe jetzt unseren Kostenvoranschlag, um mit dem Service fortzufahren.</p>}
                            {!currentServiceDone && (currentService.status === "customerApprovedKVA" || currentService.status === "shippingClarification") && <p>Wir setzen uns zeitnah mit dir in Verbindung, um alle Details bzgl. des Versands deiner Uhr zu besprechen.</p>}
                            {/*<Tooltip hasArrow placement="left" label="Die Uhr kann nicht bearbeitet werden, da sie verifiziert ist.">
                                <Button width="100%" colorScheme="blue" leftIcon={<HiPencil />} isDisabled>Uhr bearbeiten</Button>
                            </Tooltip>*/}
                            {/*currentServiceDone && <Tooltip hasArrow placement="left">
                                <Button width="100%" colorScheme="blue" leftIcon={<HiPencil />}>Uhr bearbeiten</Button>
                            </Tooltip>*/}
                            {/*<Button width="100%" onClick={() => {
                                this.setState({ drawerOpen: false })
                                this.verifyWatchModal.open(this.state.watch)
                            }} colorScheme="green" isDisabled={this.state.watch.verifiedDate || this.state.watch.waitingForVerification} leftIcon={<HiFingerPrint />}>Verifizieren lassen</Button>*/}
                            {/*<Button width="100%" colorScheme="green" leftIcon={<HiThumbUp />}>Bewerten lassen</Button>*/}
                            {/*<Box position='relative' marginTop="16px" marginBottom="8px">
                                <Divider />
                                <AbsoluteCenter bg='white' px='4'>
                                    Verwaltung
                                </AbsoluteCenter>
                            </Box>
                            <Button width="100%" leftIcon={<HiDocumentDownload />}>Zertifikat herunterladen</Button>
                            <Button width="100%" leftIcon={<HiDocument />}>Dokumente verwalten</Button>
                            <Button width="100%" leftIcon={<HiTrendingUp />}>Wertentwicklung ansehen</Button>*/}
                            {currentServiceDone && <Box position='relative' marginTop="16px" marginBottom="8px">
                                <Divider />
                                <AbsoluteCenter bg='white' px='4'>
                                    Aktionen
                                </AbsoluteCenter>
                            </Box>}
                            {/*<Button width="100%" leftIcon={<HiSwitchHorizontal />} colorScheme="orange">Uhr übertragen</Button>*/}
                            {currentServiceDone && <Button onClick={() => {
                                this.setState({ drawerOpen: false })
                                this.repairWatchModal.open(this.state.watch)
                            }} variant="outline" colorScheme="blue" width="100%" leftIcon={<HiAnnotation />}>Service anfragen</Button>}
                            {/*<Button variant="outline" colorScheme="orange" width="100%" leftIcon={<HiArchive />}>Uhr archivieren</Button>*/}
                            {/*currentServiceDone && <Button variant="outline" colorScheme="red" width="100%" leftIcon={<HiTrash />}>Uhr löschen</Button>*/}
                            {(currentService && (currentService.status === "kvaReceived")) && <Button onClick={() => {
                                this.setState({ drawerOpen: false, kvaModalOpen: true, deliveryPreference: "versand", agb: false, step: 0, selectedPositions: currentService.positions.filter(p => !p.declined).map(p => p.id) })
                            }} colorScheme="green" width="100%" leftIcon={<HiBanknotes />}>Kostenvoranschlag prüfen</Button>}
                            {(currentService && (currentService.status === "offerReceived")) && <Button onClick={() => {
                                this.setState({ drawerOpen: false, kvaModalOpen: true, step: 0, selectedPositions: currentService.positions.filter(p => !p.declined).map(p => p.id) })
                            }} colorScheme="green" width="100%" leftIcon={<HiBanknotes />}>Angebot prüfen</Button>}
                            {(currentService && (currentService.status === "approval")) && <Button onClick={() => {
                                this.setState({ drawerOpen: false, approvalModalOpen: true })
                            }} colorScheme="blue" width="100%" leftIcon={<HiChat />}>Ergebnis prüfen</Button>}
                            {(currentService && (currentService.status === "waitingForKVA")) && <Popover placement="left" isOpen={this.state.popoverOpenWithdraw}>
                                <PopoverTrigger>
                                    <Button onClick={() => this.setState({ popoverOpenWithdraw: true })} colorScheme="red" width="100%" leftIcon={<HiReply />}>Serviceanfrage zurückziehen</Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverHeader>Serviceanfrage zurückziehen</PopoverHeader>
                                    <PopoverBody>Bist du sicher, dass du die aktuelle Serviceanfrage zurückziehen möchtest?<br /><br />Diese Aktion ist nicht widerrufbar.</PopoverBody>
                                    <PopoverFooter display='flex' justifyContent='flex-end'>
                                        <ButtonGroup size='sm'>
                                            <Button onClick={() => {
                                                this.setState({ popoverOpenWithdraw: false })
                                            }}>Abbrechen</Button>
                                            <Button onClick={() => {
                                                this.setState({ popoverOpenWithdraw: false, drawerOpen: false })

                                                fetch(window.watchstorage.backendUrl + "/api/v1/watchServices/withdraw", {
                                                    method: "POST",
                                                    credentials: "include",
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                                    },
                                                    body: JSON.stringify({
                                                        id: currentService.id
                                                    })
                                                }).then(async res => {
                                                    if(res.status === 201) {
                                                        this.fetch()

                                                        window.toast({
                                                            title: 'Serviceanfrage zurückgezogen',
                                                            description: 'Wir haben deine Serviceanfrage erfolgreich storniert.',
                                                            status: 'success',
                                                            duration: 9000,
                                                            isClosable: true,
                                                        })
                                                    }
                                                })
                                            }} colorScheme='red'>Bestätigen</Button>
                                        </ButtonGroup>
                                    </PopoverFooter>
                                </PopoverContent>
                            </Popover>}
                            {(currentService && (currentService.status === "companyDeclined")) && <Button onClick={() => {
                                this.setState({ popoverOpenWithdraw: false, drawerOpen: false })

                                fetch(window.watchstorage.backendUrl + "/api/v1/watchServices/close", {
                                    method: "POST",
                                    credentials: "include",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                    },
                                    body: JSON.stringify({
                                        id: currentService.id
                                    })
                                }).then(async res => {
                                    if(res.status === 201) {
                                        this.fetch()

                                        window.toast({
                                            title: 'Serviceanfrage geschlossen',
                                            description: 'Deine Serviceanfrage wurde erfolgreich geschlossen und deine Uhr ist nun wieder bearbeitbar.',
                                            status: 'success',
                                            duration: 9000,
                                            isClosable: true,
                                        })
                                    }
                                })
                            }} colorScheme="red" width="100%" leftIcon={<HiLockClosed />}>Serviceanfrage schließen</Button>}
                        </div>
                    </DrawerBody>
                </DrawerContent>}
            </Drawer>
            <CreateWatchModal openRepairModal={watch => this.repairWatchModal.open(watch)} onRef={ref => this.createWatchModal = ref} refresh={() => {
                setTimeout(() => this.fetch(), 1000)
            }} />
            <VerifyWatchModal onRef={ref => this.verifyWatchModal = ref} refresh={() => this.fetch()} />
            <RepairWatchModal onRef={ref => this.repairWatchModal = ref} refresh={() => this.fetch()} />
            <Navigation>
                <Titles>
                    <h1>Deine Uhren</h1>
                    <h2>Verwalten, verifizieren, versichern.</h2>
                </Titles>
                <Grid>
                    <AddWatch onClick={() => this.createWatchModal.open()}>
                        <HiPlus style={{ fontSize: "48px", marginBottom: 16, color: "#16a085" }} />
                        Neue Uhr zum Inventar hinzufügen
                    </AddWatch>
                    {this.state.watches !== null && this.state.watches.map((watch, index) => <Watch key={index} services={watch.services} waitingForVerification={watch.waitingForVerification} src={watch.thumbnailImage || "/missing.jpg"} certified={!!watch.verifiedDate} onClick={() => {
                        this.setState({ drawerOpen: true, watch })
                    }} />)}
                </Grid>
            </Navigation>
        </>
    }
}
